<template>
  <KPaginatedAutocomplete
    v-bind="$attrs"
    :paginator="autocomplete"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { autocomplete } from '@/modules/collectiveAgreement/api/collectiveAgreement';

export default {
  name: 'CollectiveAgreementAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete,
  },
};
</script>
