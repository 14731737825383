import { get } from '@/application/api/implementations/app';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type { SalaryTable, Periodical, CollectiveAgreementAutocomplete } from '@/modules/collectiveAgreement/types';
import { getPaginated } from '@/application/api/getPaginated';
import type { PaginatedRequest, PaginatedResponse } from '@/application/api/getPaginated';

export const autocomplete = (data: PaginatedRequest): AxiosPromise<PaginatedResponse<CollectiveAgreementAutocomplete[]>> =>
  getPaginated('collective-agreement/autocomplete', data);

export const collectiveAgreementTree = (params: { employerId: number, startDate: string }): AxiosPromise<{data: {salaryTables: SalaryTable[]}}> =>
  get('collective-agreement/tree', { params });

export const collectiveAgreementTreePeriodical = async (salaryTableId: number, params: { payScaleId: number, candidateId: number }): Promise<AxiosResponse<{ data: Periodical[] }>> => {
  const res = await get(`salary-table/${salaryTableId}/periodical/autocomplete`, { params });

  res.data.data = res.data.data
    .map((obj: Periodical) => ({ ...obj,
      hourlyRate: obj.hourlyRate / 100,
    }))
    .sort((a: Periodical, b: Periodical) => a.periodical - b.periodical);
    
  return res;
};
