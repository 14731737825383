<template>
  <k-select
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #selection="{ item }">
      <v-list-item class="pl-0">
        <v-list-item-content>
          <v-list-item-title>
            <span class="primary--text">{{ item.text }}</span>
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.subText }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <span :class="{ 'primary--text': !item.disabled }">{{ item.text }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>{{ item.subText }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </k-select>
</template>

<script>
import KSelect from '@/components/crud/fields/KSelect.vue';

export default {
  name: 'InvoicePresetSelect',
  components: { KSelect },
};
</script>
