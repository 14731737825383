<template>
  <div id="EmployerSettings">
    <PageLayout>
      <template #header>
        <k-title>{{ $tc('employerSettings.title', 2) }}</k-title>
      </template>
      <template #flow-actions>
        <k-btn
          :disabled="!apiLoaded"
          @click="updateEmployerSettings"
        >
          {{ $t('actions.save') }}
        </k-btn>
      </template>
      <template
        v-if="employerSettings"
        #default
      >
        <k-field-group language-prefix="employerSettings.fields">
          <v-col lg="4">
            <v-row>
              <KRadioGroup
                v-model="employerSettings.shiftHandlingId"
                :items="shiftHandlingOptions"
                field="responsibleForPlacings"
              />

              <KRadioGroup
                v-model="employerSettings.timeRegistrationHandlingId"
                :items="timeRegistrationHandlingOptions"
                field="timeRegistrationHandling"
              />

              <v-col
                cols="12"
                class="px-0"
              >
                <v-tooltip
                  right
                  :disabled="!employerSettings.selectedCollectiveAgreementInUse"
                >
                  <template #activator="{ on }">
                    <div v-on="on">
                      <CollectiveAgreementAutocomplete
                        v-model="employerSettings.collectiveAgreementId"
                        :return-object="false"
                        :disabled="employerSettings.selectedCollectiveAgreementInUse"
                        field="collectiveAgreement"
                      />
                    </div>
                  </template>
                  <span>{{ $t('employerSettings.messages.ClaInUse') }}</span>
                </v-tooltip>
              </v-col>

              <h4 class="primary--text py-4">
                {{ $t('employerSettings.invoiceBillingIntervalZZP') }}
              </h4>

              <v-alert
                :value="groupedOnCandidateIsSelected"
                outlined
                color="primary"
                icon="$info"
                transition="scale-transition"
              >
                {{ $t('employerSettings.messages.intervalOptionNotPossible') }}
              </v-alert>
             
              <k-select
                v-for="interval in employerSettings.invoiceTypeBillingIntervals"
                :key="interval.invoiceTypeId"
                v-model="interval.invoiceBillingIntervalId"
                :items="billingIntervalOptions"
                :label="$t(`invoice.invoiceType.${getEnumKeyByValue(invoiceType, interval.invoiceTypeId)}`)"
              >
                <template
                  v-if="interval.invoiceTypeId === invoiceType.INTERMEDIARY_TO_EMPLOYER_SELF_EMPLOYED_WAGE_AND_MEDIATION"
                  #label
                >
                  {{ $t('invoice.invoiceType.INTERMEDIARY_TO_EMPLOYER_SELF_EMPLOYED_WAGE_AND_MEDIATION') }}
                </template>
              </k-select>

              <v-alert
                :value="followCandidateIsSelected"
                class="mt-4"
                outlined
                color="primary"
                icon="$info"
                transition="scale-transition"
              >
                {{ $t('employerSettings.messages.groupingOptionNotPossible') }}
              </v-alert>

              <InvoicePresetAutocomplete
                v-model="employerSettings.invoiceSplitGroupPresetTypeId"
                :class="{ 'mt-4': !followCandidateIsSelected }"
                :items="invoiceSplitGroupPresetTypeOptions"
                field="invoiceSplitGroupPresetType"
              />

              <KCheckboxGroup
                v-model="employerSettings.externalInvoicesTypeIds"
                :items="externalInvoicesOptions"
                field="externalInvoices"
                hide-details
              />

              <KRadioGroup
                v-model="employerSettings.timesheetHandlingTypeId"
                :items="timesheetHandlingOptions"
                field="timesheetHandling"
              />
            </v-row>
          </v-col>
        </k-field-group>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import { employerShiftHandling } from '@/application/enums/employerShiftHandling.js';
import { timesheetHandling } from '@/application/enums/timesheetHandling.js';
import { invoiceType } from '@/application/enums/invoiceType';
import { employerTimeRegistrationHandling } from '@/application/enums/employerTimeRegistrationHandling.js';
import { invoiceBillingIntervalType } from '@/application/enums/invoiceBillingIntervalType.js';
import eventBus from '@/application/eventBus.ts';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KCheckboxGroup from '@/components/crud/fields/KCheckboxGroup.vue';
import KBtn from '@/components/KButton.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KTitle from '@/components/layout/KTitle.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import { getEmployerSettings, shiftHandlerUpdate } from '@/modules/employer/api/settings.js';
import CollectiveAgreementAutocomplete
  from '@/modules/collectiveAgreement/components/CollectiveAgreementAutocomplete.vue';
import InvoicePresetAutocomplete from '@/modules/employer/components/InvoicePresetSelect.vue';
import { invoiceSplitGroupPresetType } from '@/application/enums/invoiceSplitGroupPresetType';
import { getTranslatedEnumOptions, getEnumKeyByValue } from '@/application/helpers/enum.js';

export default {
  name: 'EmployerSettings',
  components: {
    InvoicePresetAutocomplete,
    KFieldGroup,
    CollectiveAgreementAutocomplete,
    KRadioGroup,
    KCheckboxGroup,
    KBtn,
    KSelect,
    KTitle,
    PageLayout,
  },
  props: {
    employer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employerSettings: null,
      apiLoaded: false,
    };
  },
  computed: {
    invoiceType: () => invoiceType,
    shiftHandlingOptions() {
      return getTranslatedEnumOptions(employerShiftHandling, 'employerSettings.employerShiftHandlingValues');
    },
    timeRegistrationHandlingOptions() {
      return getTranslatedEnumOptions(employerTimeRegistrationHandling, 'employerSettings.timeRegistrationHandlingValues');
    },
    externalInvoicesOptions() {
      return getTranslatedEnumOptions(invoiceType, 'invoice.invoiceType').filter(type => [
        invoiceType.INTERMEDIARY_TO_EMPLOYER_SELF_EMPLOYED_WAGE_AND_MEDIATION,
        invoiceType.INTERMEDIARY_TO_EMPLOYER_TEMPORARY_WORKER_WAGE_AND_MEDIATION,
        invoiceType.INTERMEDIARY_TO_EMPLOYER_SELF_EMPLOYED_MEDIATION,
      ].includes(type.value));
    },
    timesheetHandlingOptions() {
      return getTranslatedEnumOptions(timesheetHandling, 'employerSettings.timesheetHandlingValues');
    },
    billingIntervalOptions() {
      return getTranslatedEnumOptions(invoiceBillingIntervalType, 'employerSettings.invoiceBillingIntervalValues')
        .map(intervalType => ({
          ...intervalType,
          disabled: this.groupedOnCandidateIsSelected && intervalType.value === invoiceBillingIntervalType.FOLLOW_CANDIDATE,
        }));
    },
    followCandidateIsSelected() {
      return this.employerSettings.invoiceTypeBillingIntervals
        .map(invoiceType => invoiceType.invoiceBillingIntervalId)
        .includes(invoiceBillingIntervalType.FOLLOW_CANDIDATE);
    },
    groupedOnCandidateIsSelected() {
      return [
        invoiceSplitGroupPresetType.SPLIT_EMPLOYER_GROUP_DEPARTMENT,
        invoiceSplitGroupPresetType.SPLIT_EMPLOYER_GROUP_FUNCTION,
      ].includes(this.employerSettings.invoiceSplitGroupPresetTypeId);
    },
    invoiceSplitGroupPresetTypeOptions() {
      return Object.keys(invoiceSplitGroupPresetType).map(type => ({
        value: invoiceSplitGroupPresetType[type],
        text: this.$t(`employerSettings.invoiceSplitGroupPresetValues.${type}.text`),
        subText: this.$t(`employerSettings.invoiceSplitGroupPresetValues.${type}.subText`),
        disabled: this.followCandidateIsSelected && [
          invoiceSplitGroupPresetType.SPLIT_EMPLOYER_GROUP_DEPARTMENT,
          invoiceSplitGroupPresetType.SPLIT_EMPLOYER_GROUP_FUNCTION,
        ].includes(invoiceSplitGroupPresetType[type]),
      }));
    },
  },
  async created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.settings' },
        text: this.$tc('employerSettings.title', 2),
      },
    ]);

    const employerSettings = await getEmployerSettings({
      employerId: this.employer.id,
    });

    this.employerSettings = {
      ...employerSettings.data.data,
      externalInvoicesTypeIds: employerSettings.data.data.externalInvoicesTypeIds?.map(typeId => Number(typeId)) || [],
      invoiceTypeBillingIntervals: employerSettings.data.data.invoiceTypeBillingIntervals.map(interval => ({
        invoiceTypeId: Number(interval.invoiceTypeId),
        invoiceBillingIntervalId: Number(interval.invoiceBillingIntervalId),
      })),
    };

    this.$nextTick(() => this.apiLoaded = true);
  },
  methods: {
    getEnumKeyByValue,
    async updateEmployerSettings() {
      if (!this.apiLoaded) return;

      this.apiLoaded = false;

      try {
        await shiftHandlerUpdate({
          employerId: this.employer.id,
          employerSettings: this.employerSettings,
        });

        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('actions.resourceUpdated', { resource: this.$tc('employerSettings.title', 1) }),
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
        throw error;
      } finally {
        this.apiLoaded = true;
      }

    },
      
  },
};
</script>
